import { adminConstants, BACKOFFICE_SOURCE } from '../_constants'
import { adminService } from '../_services'
import { alertActions } from './'

export const adminActions = {
	addWhitelist,
	deleteFromBlacklist,
	checkBlacklist,
	addBlacklist,
	updateBlacklistUser,
	addCoupon,
	clear,
	validateCompany,
	generateCvu,
	downloadQR,
}

function addWhitelist(external_ref) {
	const request = () => ({
		type: adminConstants.ADDWHITELIST_REQUEST,
		external_ref: external_ref,
	})
	const success = () => ({
		type: adminConstants.ADDWHITELIST_SUCCESS,
		external_ref: external_ref,
	})
	const failure = (error) => ({
		type: adminConstants.ADDWHITELIST_FAILURE,
		error,
		external_ref,
	})

	return async (dispatch) => {
		dispatch(request(external_ref))
		try {
			const admin = await adminService.addWhitelist(external_ref)
			dispatch(success(admin))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function deleteFromBlacklist(
	external_ref,
	device_id,
	advertising_id,
	description,
	author
) {
	const request = (ref) => ({
		type: adminConstants.DELETEFROMBLACKLIST_REQUEST,
		external_ref: ref.external_ref,
		device_id: ref.device_id,
		advertising_id: ref.advertising_id,
	})
	const success = (ref) => ({
		type: adminConstants.DELETEFROMBLACKLIST_SUCCESS,
		command: ref.command,
		rowCount: ref.rowCount,
		tx: ref.tx,
	})
	const failure = (error) => ({
		type: adminConstants.DELETEFROMBLACKLIST_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ external_ref, device_id }))
		try {
			const admin = await adminService.deleteFromBlacklist(
				external_ref,
				device_id,
				advertising_id,
				description,
				author,
				BACKOFFICE_SOURCE
			)
			dispatch(success(admin))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function checkBlacklist(external_ref, device_id, advertising_id) {
	const request = (ref) => ({
		type: adminConstants.CHECKBLACKLIST_REQUEST,
		external_ref: ref.external_ref,
		device_id: ref.device_id,
		advertising_id: ref.advertising_id,
	})
	const success = (ref) => ({
		type: adminConstants.CHECKBLACKLIST_SUCCESS,
		valid: ref.valid,
		tx: ref.tx,
	})
	const failure = (error) => ({
		type: adminConstants.CHECKBLACKLIST_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ external_ref, device_id, advertising_id }))
		try {
			const res = await adminService.checkBlacklist(
				external_ref,
				device_id,
				advertising_id
			)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			const { message, fields = undefined } = error
			dispatch(failure(message))
			dispatch(alertActions.error(message, fields))
		}
	}
}

function addBlacklist(
	external_ref,
	device_id,
	advertising_id,
	description,
	author
) {
	const request = (ref) => ({
		type: adminConstants.ADDBLACKLIST_REQUEST,
		external_ref: ref.external_ref,
		device_id: ref.device_id,
		advertising_id: ref.advertising_id,
	})
	const success = (ref) => ({
		type: adminConstants.ADDBLACKLIST_SUCCESS,
		command: ref.command,
		rowCount: ref.rowCount,
		tx: ref.tx,
	})
	const failure = (error) => ({
		type: adminConstants.ADDBLACKLIST_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ external_ref, device_id }))
		try {
			const res = await adminService.addBlacklist(
				external_ref,
				device_id,
				advertising_id,
				description,
				author,
				BACKOFFICE_SOURCE
			)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function updateBlacklistUser(external_ref, device_id, active) {
	const request = (ref) => ({
		type: adminConstants.UPDATEBLACKLISTUSER_REQUEST,
		external_ref: ref.external_ref,
		device_id: ref.device_id,
		active: ref.active,
	})
	const success = (ref) => ({
		type: adminConstants.UPDATEBLACKLISTUSER_SUCCESS,
		updatedBlacklistStatus: active,
		external_ref: external_ref,
		device_id: device_id,
		command: ref.command,
		rowCount: ref.rowCount,
		tx: ref.tx,
	})
	const failure = (error) => ({
		type: adminConstants.UPDATEBLACKLISTUSER_FAILURE,
		external_ref: external_ref,
		device_id: device_id,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ external_ref, device_id, active }))
		try {
			const res = await adminService.updateBlacklistUser(
				external_ref,
				device_id,
				active
			)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function addCoupon(form) {
	const request = (name) => ({
		type: adminConstants.ADDCOUPON_REQUEST,
		couponName: name,
	})
	const success = (res, name) => ({
		type: adminConstants.ADDCOUPON_SUCCESS,
		couponName: name,
		res: res,
	})
	const failure = (error, name) => ({
		type: adminConstants.ADDCOUPON_FAILURE,
		couponName: name,
		error,
	})

	const couponName = (f) => {
		return f.find((formItem) => formItem[0] === 'name')[1]
	}

	return async (dispatch) => {
		const name = couponName(form)
		dispatch(request(name))
		try {
			const res = await adminService.addCoupon(form)
			dispatch(success(res, name))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString(), name))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function clear() {
	return (dispatch) => {
		dispatch({ type: adminConstants.CLEAR })
	}
}

function validateCompany(cuit) {
	const request = (ref) => ({
		type: adminConstants.VALIDATECUIT_REQUEST,
		cuit: ref.cuit,
	})
	const success = (ref) => ({
		data: ref,
		type: adminConstants.VALIDATECUIT_SUCCESS,
	})
	const failure = (error) => ({
		type: adminConstants.VALIDATECUIT_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ cuit }))
		try {
			const res = await adminService.validateCompany(cuit)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function generateCvu(data) {
	const { cuit, externalRef, fantasyName, businessName, hasQr } = data
	const request = (ref) => ({
		type: adminConstants.GENERATECVU_REQUEST,
		cuit: ref.cuit,
	})
	const success = (ref) => ({
		data: ref,
		type: adminConstants.GENERATECVU_SUCCESS,
	})
	const failure = (error) => ({
		type: adminConstants.GENERATECVU_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ cuit, externalRef, fantasyName, businessName, hasQr }))
		try {
			const res = await adminService.generateCvu(
				cuit,
				externalRef,
				fantasyName,
				businessName,
				hasQr
			)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}

function downloadQR(cuit, city) {
	const request = (ref) => ({
		type: adminConstants.DOWNLOADQR_REQUEST,
		cuit: ref.cuit,
	})
	const success = (ref) => ({
		data: ref.qr_image,
		type: adminConstants.DOWNLOADQR_SUCCESS,
	})
	const failure = (error) => ({
		type: adminConstants.DOWNLOADQR_FAILURE,
		error,
	})

	return async (dispatch) => {
		dispatch(request({ cuit }))
		try {
			const res = await adminService.downloadQR(cuit, city)
			dispatch(success(res))
			dispatch(alertActions.clear())
		} catch (error) {
			dispatch(failure(error.toString()))
			dispatch(alertActions.error(error.toString()))
		}
	}
}
