export function parseCouponForm(form) {
	const parsed = {}
	form.forEach(([key, val]) => {
		if (!isNaN(val)) {
			parsed[key] = parseInt(val)
		} else if (val === 'true') {
			parsed[key] = true
		} else if (val === 'false') {
			parsed[key] = false
		} else {
			parsed[key] = val
		}
	})
	return parsed
}
