import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from '../_reducers'

let middleWare
if (process.env.NODE_ENV === 'development') {
	middleWare = applyMiddleware(thunkMiddleware, createLogger())
} else {
	middleWare = applyMiddleware(thunkMiddleware)
}

export const store = createStore(rootReducer, middleWare)
