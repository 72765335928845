import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Button, Typography } from '@material-ui/core'
import { adminActions } from '../_actions'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	buttonGroup: {
		display: 'flex',
	},
}))

function CouponTitle() {
	const addCouponSuccess = useSelector((state) => state.admin.addCouponSuccess)
	const addingCoupon = useSelector((state) => state.admin.addingCoupon)
	const couponName = useSelector((state) => state.admin.name)
	if (addingCoupon) {
		return (
			<>
				<Typography variant='h4'>Agregando Cupon {couponName}</Typography>
				<CircularProgress />
			</>
		)
	}
	if (addCouponSuccess) {
		return <Typography variant='h4'>Cupon {couponName} agregado!</Typography>
	}
	if (addCouponSuccess === false) {
		return <Typography variant='h4'>Fallo agregar Cupon {couponName}</Typography>
	}
	return <Typography variant='h4'>Agregar cupon</Typography>
}

const ConfirmPage = (props) => {
	const {
		name,
		amount,
		code,
		payment_type,
		activated_at,
		finished_at,
		max_usage,
		campaign,
	} = props

	const addingCoupon = useSelector((state) => state.admin.addingCoupon)
	const dispatch = useDispatch()
	const classes = useStyles()

	const handleSubmit = () => {
		const entries = [...props.storageFormData]
		dispatch(adminActions.addCoupon(entries))
	}

	return (
		<section className={classes.paper}>
			<CouponTitle />
			<div>
				<p>
					<strong>Nombre:</strong> {name}
				</p>
				<p>
					<strong>Codigo:</strong> {code}
				</p>
				<p>
					<strong>Porcentaje de descuento:</strong> {amount}
				</p>
				<p>
					<strong>Tipo de pago:</strong> {payment_type}
				</p>
				<p>
					<strong>Fecha inicio:</strong> {activated_at}
				</p>
				<p>
					<strong>Fecha finalizacion:</strong> {finished_at}
				</p>
				<p>
					<strong>Cantidad de usos:</strong> {max_usage}
				</p>
				<p>
					<strong>Campaña:</strong> {campaign}
				</p>
			</div>
			<div className={classes.buttonGroup}>
				<Button onClick={props.goBack}>Volver atras</Button>
				<Button disabled={addingCoupon} onClick={handleSubmit} color="primary">
					Confirmar envio
				</Button>
			</div>
		</section>
	)
}

export default ConfirmPage
