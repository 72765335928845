import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
//import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { adminService } from '../_services'
//import { adminActions } from '../_actions'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import { BACKOFFICE_SOURCE, BLACKLIST_ADD_CATEGORIES } from '../_constants'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	gridContainer: {
		margin: 0,
		marginTop: '10vh',
		width: '100%',
	},
	paper: {
		marginTop: theme.spacing(1),
		marginBottom: 0,
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		display: 'flex',
		alignItems: 'flex-start',
		textAlign: 'center',
		width: '100%',
		justifyContent: 'space-evenly',
		padding: theme.spacing(1, 2),
		height: 'fit-content',
	},
	//appBarSpacer: theme.mixins.toolbar,
	textarea: {
		width: '40vw',
		height: '30vh',
		minHeight: '10vh',
		minWidth: '10vw',
		marginBottom: theme.spacing(2),
	},
	textWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	listWrapper: {
		maxHeight: '90%',
		marginTop: theme.spacing(2),
		overflowY: 'auto',
		border: '1px solid #BDC7DA',
		boxSizing: 'border-box',
		borderRadius: '4px',
	},
	success: {
		backgroundColor: 'rgba(0, 255, 30, 0.3)',
	},
	failure: {
		backgroundColor: 'rgba(255, 0, 0, 0.3)',
	},
	denseList: {
		padding: 0,
		maxHeight: '70vh',
		overflowY: 'scroll',
	},
	blacklistElement: { whiteSpace: 'pre-line', lineHeight: '0.7 !important' },
	error: {
		color: red[300],
		fontWeight: 'bold',
		marginTop: '20px',
	},
}))

/*
 * \n -> newLine, separa rows
 * \t -> tab, separa celdas (columnas)
 * parseSheet devuelve matriz (array de arrays)
 */
function parseSheet(initial, copiedFromExcel) {
	if (!initial) {
		return
	}
	const parsed = []
	initial
		.split('\n')
		.forEach((row) => parsed.push(row.split(copiedFromExcel ? '\t' : ',')))
	return parsed
}

function StatusIcon({ status }) {
	switch (status) {
		case 'loading':
			return <CircularProgress size={24} />
		case 'success':
			return <DoneIcon size={24} />
		case 'failure':
			return <CloseIcon size={24} />
		default:
			return <CircularProgress size={24} />
	}
}

function BlacklistItem({ user, description, author }) {
	const [reqStatus, setReqStatus] = useState('loading')
	const classes = useStyles()
	const [external_ref, device_id, advertising_id] = user
	const banUser = React.useCallback(async () => {
		try {
			const res = await adminService.addBlacklist(
				external_ref,
				device_id,
				advertising_id,
				description,
				author,
				BACKOFFICE_SOURCE
			)
			if (res) {
				setReqStatus('success')
			}
		} catch (err) {
			setReqStatus('failure')
		}
	})
	useEffect(() => {
		banUser()
	}, [])

	return (
		<ListItem className={reqStatus ? classes[reqStatus] : ''}>
			<ListItemIcon>
				<StatusIcon status={reqStatus} />
			</ListItemIcon>
			<Typography>
				<strong>external_ref:</strong> {external_ref ? external_ref : 'null'}
				<br />
				<strong>device_id:</strong> {device_id ? device_id : 'null'}
				<br />
				<strong>advertising_id:</strong> {advertising_id ? advertising_id : 'null'}
			</Typography>
		</ListItem>
	)
}

function RenderList({ items, description, author }) {
	const classes = useStyles()
	if (!items) {
		return null
	}
	return (
		<div className={classes.listWrapper}>
			<List dense className={classes.denseList}>
				{items.map((user) => {
					return (
						<>
							<BlacklistItem
								user={user}
								description={description}
								author={author}
								key={user}
							/>{' '}
							<Divider />
						</>
					)
				})}
			</List>
		</div>
	)
}

export function BatchUpload() {
	const classes = useStyles()
	const [toBlacklist, setToBlacklist] = useState()
	const [copiedFromExcel, setCopiedFromExcel] = useState(true)
	const [description, setDescription] = useState('')
	const [additionalInfo, setAdditionalInfo] = useState('')
	const [descriptionError, setDescriptionError] = useState(false)
	const textRef = React.useRef(null)
	const options = BLACKLIST_ADD_CATEGORIES
	const author = useSelector((state) => {
		const {
			authentication: {
				user: { name, last_name, account_hash },
			},
		} = state
		return `${name} ${last_name} | ${account_hash}`
	})
	const handleOnBtnClick = () => {
		if (!description) {
			setDescriptionError(true)
			return
		}
		setToBlacklist(parseSheet(textRef.current.value, copiedFromExcel))
		setDescriptionError(false)
	}
	const handleIsFromExcel = (e) => {
		setCopiedFromExcel(e.target.checked)
	}
	const handleDescriptionChange = (e) => {
		setDescription(e.target.value)
	}
	const handleAdditionalInfoChange = (e) => {
		setAdditionalInfo(e.target.value)
	}
	return (
		<Grid container spacing={3} className={classes.gridContainer}>
			<Paper className={classes.paper}>
				{/*<div className={classes.appBarSpacer} />*/}
				<div className={classes.textWrapper}>
					<Typography variant='h6' gutterBottom>
						👮 Usuarios a BL 👮
					</Typography>
					<Typography variant='subtitle2'>
						Es un copy-paste del excel de usuarios a BL?
						<Switch
							checked={copiedFromExcel}
							onChange={handleIsFromExcel}
							name='checkedB'
							color='primary'
						/>
					</Typography>
					<Typography variant='subtitle2' gutterBottom>
						<strong>Formato*:</strong>{' '}
						{`${
							copiedFromExcel
								? 'external_ref    device_id    advertising_id'
								: 'external_ref, device_id, advertising_id'
						}`}
					</Typography>
					{copiedFromExcel && (
						<>
							<Typography variant='subtitle2' gutterBottom>
								*Un tab de espacio entre valores.
								<br /> Esto es automático copiando desde sheets/excel
							</Typography>
						</>
					)}
					<textarea ref={textRef} className={classes.textarea} />
					<FormControl variant='outlined' error={descriptionError}>
						<InputLabel id='description'>Descripción *</InputLabel>
						<Select
							id='description'
							required
							labelId='description'
							value={description}
							onChange={handleDescriptionChange}
							label='Descripción *'
						>
							{options.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						variant='outlined'
						margin='normal'
						value={additionalInfo}
						onChange={handleAdditionalInfoChange}
						label='Información adicional'
						name='additional_info'
					/>
					<div style={{ height: '12px' }} />
					<Button onClick={handleOnBtnClick} variant='contained' color='primary'>
						Agregar a Blacklist
					</Button>
					{descriptionError && (
						<Typography className={classes.error} variant='subtitle2' align='left'>
							Es necesaria una descripción
						</Typography>
					)}
				</div>
				<RenderList
					items={toBlacklist}
					description={`${description} - Batch | ${additionalInfo}`}
					author={author}
				/>
			</Paper>
		</Grid>
	)
}
