import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { green, red } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminActions } from '../_actions'
import { useSuccess } from '../_hooks'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	paper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2),
		flexDirection: 'column',
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	loading: {
		margin: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	success: {
		backgroundColor: green[300],
	},
	failure: {
		backgroundColor: red[300],
	},
	clear: {
		backgroundColor: '#ffffff',
	},
	title: {
		fontWeight: 'bold',
	},
	error: {
		color: red[300],
		marginTop: '20px',
		fontWeight: 'bold',
	},
}))

function CheckBlacklist() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [error, setError] = useState(false)
	const [checkBlacklistInputs, setCheckBlacklistInputs] = useState({
		external_ref: '',
		device_id: '',
		advertising_id: '',
	})
	const success = useSelector((state) => useSuccess(state.admin.inBlacklist))
	const fields = useSelector((state) => state.alert.fields)

	const { external_ref, device_id, advertising_id } = checkBlacklistInputs
	const checkingBlacklist = useSelector((state) => state.admin.checkingBlacklist)

	const handleBlacklistInputChange = (e) => {
		const { name, value } = e.target
		setCheckBlacklistInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	const checkIfErrors = () => {
		setError(!external_ref && !device_id && !advertising_id)

		return !external_ref && !device_id && !advertising_id
	}

	const checkBlacklist = () => {
		if (checkIfErrors()) return
		dispatch(adminActions.checkBlacklist(external_ref, device_id, advertising_id))
		setError(false)
	}

	return (
		<>
			<Typography variant='subtitle1' className={classes.title}>
				Checkear si esta en la Blacklist
			</Typography>
			<Typography variant='caption' gutterBottom>
				Verde = Habilitado. Rojo = en blacklist
			</Typography>
			<Paper className={clsx(classes.paper, classes[success])}>
				{checkingBlacklist ? (
					<CircularProgress size={24} className={classes.loading} />
				) : (
					<>
						<TextField
							variant='outlined'
							margin='normal'
							value={external_ref}
							onChange={handleBlacklistInputChange}
							className={classes.input}
							label='external_ref'
							name='external_ref'
							error={error}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							value={device_id}
							onChange={handleBlacklistInputChange}
							className={classes.input}
							label='device_id'
							error={error}
							name='device_id'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							value={advertising_id}
							onChange={handleBlacklistInputChange}
							className={classes.input}
							label='advertising_id'
							error={error}
							name='advertising_id'
						/>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={checkBlacklist}
						>
							Check if Blacklist
						</Button>
						{error && (
							<Typography className={classes.error} variant='subtitle2'>
								Debe enviar por lo menos un campo
							</Typography>
						)}
					</>
				)}
			</Paper>
			{fields && fields.length > 0 && (
				<Typography variant='subtitle2' className={classes.error} align='left'>
					{`${
						fields.length > 1
							? 'Los campos que se encuentran en blacklist son: '
							: 'El campo que se encuentra en blacklist es: '
					}`}
					{fields.map((field, i) => (i === 0 ? field : `, ${field}`))} <br />
					Se recomienda bloquear los campos buscados que no se encuentren en la
					lista.
				</Typography>
			)}
		</>
	)
}

export { CheckBlacklist }
