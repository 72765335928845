import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userActions, alertActions, adminActions } from '../_actions'
import tapLogo from '../_assets/header_logo.png'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: 'white',
	},
	btnWrapper: {
		position: 'relative',
	},
	buttonProgress: {
		color: 'white',
	},
	logo: {
		margin: theme.spacing(1),
		width: '125px',
		height: '65px',
	},
	input: {
		backgroundColor: 'white',
	},
}))

export default function LoginPage() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const location = useLocation()
	const alert = useSelector((state) => state.alert)
	const loggingIn = useSelector((state) => state.authentication.loggingIn)
	const [submitted, setSubmitted] = useState(false)
	const [inputs, setInputs] = useState({
		email: '',
		pin: '',
	})
	const { email, pin } = inputs

	useEffect(() => {
		localStorage.removeItem('user')
		dispatch(alertActions.clear())
		dispatch(adminActions.clear())
	}, [])

	function handleChange(e) {
		const { name, value } = e.target
		setInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	function handlePinChange(e) {
		const { value } = e.target
		const filteredPin = value.match(/\d/g)?.slice(0, 4)
		setInputs((inputs) => ({ ...inputs, pin: filteredPin?.join('') || '' }))
	}

	function handleSubmit(e) {
		e.preventDefault()
		setSubmitted(true)
		if (email && pin && pin.length === 4) {
			const { from } = location.state || { from: { pathname: '/' } }
			dispatch(userActions.login(email, pin, from))
		}
	}

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				<img className={classes.logo} src={tapLogo} alt='Tap Logo' />
				<form className={classes.form} onSubmit={handleSubmit} noValidate>
					<TextField
						variant='outlined'
						margin='normal'
						className={classes.input}
						required
						fullWidth
						id='email'
						label='Tap Email'
						name='email'
						autoComplete='email'
						autoFocus
						value={email}
						onChange={handleChange}
						error={(submitted && !email) || alert.type === 'alert-danger'}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						className={classes.input}
						required
						fullWidth
						name='pin'
						label='PIN'
						type='password'
						id='pin'
						autoComplete='current-password'
						pattern='[0-9]*'
						inputMode='numeric'
						value={pin}
						onChange={handlePinChange}
						error={
							(submitted && (!pin || pin.length !== 4)) ||
							alert.type === 'alert-danger'
						}
					/>
					<div className='btnWrapper'>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							className={classes.submit}
						>
							{loggingIn ? (
								<CircularProgress size={24} className={classes.buttonProgress} />
							) : (
								'Sign In'
							)}
						</Button>
					</div>
				</form>
				{alert.message && <h3>{alert.message}</h3>}
			</div>
		</Container>
	)
}

export { LoginPage }
