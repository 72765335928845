import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Grid } from '@material-ui/core'
import { ValidateCompany } from './ValidateCompany'
import { GenerateCvu } from './GenerateCvu'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(1),
		marginBottom: 0,
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		overflowY: 'auto',
		flexGrow: 1,
	},
	appBarSpacer: theme.mixins.toolbar,
	gridContainer: {
		margin: 0,
		width: '100%',
	},
	tabPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	tabPanelChildren: {
		width: '70%',
	},
}))

function TabPanel(props) {
	const classes = useStyles()
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			className={classes.tabPanel}
		>
			{value === index && (
				<div className={classes.tabPanelChildren}>{children}</div>
			)}
		</div>
	)
}

export function CompanyValidation() {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className={classes.paper}>
			<div className={classes.appBarSpacer} />
			<Grid container spacing={3} className={classes.gridContainer}>
				<Grid item xs={12} lg={9}>
					<ValidateCompany />
					<GenerateCvu />
				</Grid>
			</Grid>
		</div>
	)
}
