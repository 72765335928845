import React, { useState, useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { history } from '../_helpers'

//import { alertActions } from '../_actions'
import { PrivateRoute } from '../_components'
import { HomePage } from '../HomePage'
import { LoginPage } from '../LoginPage'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

function App() {
	const alert = useSelector((state) => state.alert)
	const [openAlert, setOpenAlert] = useState(false)
	const handleOnClose = () => {
		setOpenAlert(false)
	}
	useEffect(() => {
		alert?.message && setOpenAlert(true)
	}, [alert])

	return (
		<>
			<Snackbar open={openAlert} autoHideDuration={5000} onClose={handleOnClose}>
				<MuiAlert elevation={6} variant='filled' severity={alert?.type}>
					{alert?.message}
				</MuiAlert>
			</Snackbar>
			<Router history={history}>
				<Switch>
					<PrivateRoute exact path='/' component={HomePage} />
					<Route path='/login' component={LoginPage} />
					<Redirect from='*' to='/' />
				</Switch>
			</Router>
		</>
	)
}

export { App }
