import { baseUrl } from '../_constants/baseTapUrl'
export const userService = {
	login,
	logout,
}
async function login(email, password) {
	const defaultContext = { platform: 'android', version: '99.99.99' }
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password, ...defaultContext }),
	}
	try {
		const loginResponse = await fetch(`${baseUrl}/platform/login`, requestOptions)
		if (!loginResponse.ok) {
			const text = await loginResponse.text()
			throw new Error(text)
		}
		const loginBody = await loginResponse.json()
		const loginChinoHeader = loginResponse.headers.get('x-chino-token')
		loginBody.user.chinoToken = loginChinoHeader
		localStorage.setItem('user', JSON.stringify(loginBody.user))
		return loginBody.user
	} catch (err) {
		console.warn(err)
		return Promise.reject(err)
	}
}

function logout() {
	localStorage.removeItem('user')
}
