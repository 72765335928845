import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	TextField,
	Typography,
	Divider,
	Button,
	Select,
	MenuItem,
	InputLabel,
} from '@material-ui/core'

const getFormattedTomorrow = () => {
	const now = new Date()
	now.setDate(now.getDate() + 1)
	now.setHours(now.getHours() - 3) // arg is -3
	return now.toISOString().replace(/T/, ' ').replace(/\..+/, '')
}

const getFormattedToday = () => {
	const now = new Date()
	now.setHours(now.getHours() - 3) // arg is -3
	return now.toISOString().replace(/T/, ' ').replace(/\..+/, '')
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(50),
		marginBottom: 0,
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		width: '100%',
		justifyContent: 'center',
	},
	formWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		textAlign: 'center',
		width: '100%',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	formSubgroup: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-between',
		padding: `0 ${theme.spacing(2)}px`,
	},
	divider: {
		margin: theme.spacing(2),
		height: '1px',
	},
	readonlyInput: {
		opacity: 0.5,
	},
}))

function Cupones(props) {
	const classes = useStyles()

	const handleSubmit = (e) => {
		e.preventDefault()
		const formData = new FormData(e.target)
		props.setStorageFormData(formData)
		convertFormDataToObject(formData)
		props.setIsShowingInputs(false)
	}

	const convertFormDataToObject = (formData) => {
		let formObject = {}
		formData.forEach((value, key) => {
			if (!Reflect.has(formObject, key)) {
				formObject[key] = value
				return
			}
			if (!Array.isArray(formObject[key])) {
				formObject[key] = [formObject[key]]
			}
			formObject[key].push(value)
		})

		props.setFormDataInput(formObject)
	}

	return (
		<div className={classes.paper}>
			<Typography variant='h4'>Datos del cupon:</Typography>
			<Divider flexItem className={classes.divider} />
			<form onSubmit={handleSubmit} className={classes.formWrapper}>
				<div className={classes.formSubgroup}>
					<TextField
						id='amount'
						label='% Descuento'
						name='amount'
						defaultValue='1'
						variant='outlined'
						margin='dense'
						type='number'
					/>
					<TextField
						id='max_amount'
						label='Monto maximo'
						name='max_amount'
						defaultValue='200'
						variant='outlined'
						margin='dense'
						type='number'
					/>
					<TextField
						id='code'
						label='Codigo'
						name='code'
						defaultValue='lukipromo2'
						variant='outlined'
						margin='dense'
					/>
					<TextField
						id='name'
						label='Nombre'
						name='name'
						defaultValue='lukipromo2'
						variant='outlined'
						margin='dense'
					/>
					<InputLabel shrink id='campaign'>
						Campaña
					</InputLabel>
					<Select
						id='campaign'
						name='campaign'
						defaultValue='NONE'
						variant='outlined'
						margin='dense'
					>
						<MenuItem value='NONE'>NONE</MenuItem>
						<MenuItem value='ACQUISITION'>ACQUISITION</MenuItem>
					</Select>
				</div>
				<div className={classes.formSubgroup}>
					<TextField
						id='modality'
						label='Modalidad'
						name='modality'
						defaultValue='DISCOUNTS'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<TextField
						id='accreditation_type'
						label='Tipo de acreditacion'
						name='accreditation_type'
						defaultValue='DISCOUNTS_WITH_TOP'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<InputLabel shrink id='payment_method'>
						Metodo de pago
					</InputLabel>
					<Select
						id='payment_method'
						name='payment_method'
						defaultValue='ALL'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					>
						<MenuItem value='ALL'>Todas</MenuItem>
						<MenuItem value='DEBIT_CARD'>Debito</MenuItem>
						<MenuItem value='CREDIT_CARD'>Credito</MenuItem>
						<MenuItem value='WALLET_BALANCE'>Billetera</MenuItem>
					</Select>
					<InputLabel shrink id='payment_type'>
						Tipo de pago
					</InputLabel>
					<Select
						id='payment_type'
						name='payment_type'
						defaultValue='PAY_SERVICES'
						variant='outlined'
						margin='dense'
					>
						<MenuItem value='PAY_QR'>PAY_QR</MenuItem>
						<MenuItem value='PAY_SERVICES'>PAY_SERVICES</MenuItem>
						<MenuItem value='RECHARGE_MOVIL'>RECHARGE_MOVIL</MenuItem>
					</Select>
					<TextField
						id='promo_type'
						label='Tipo de promo'
						name='promo_type'
						defaultValue='DISCOUNTS_CODE'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
				</div>
				<div className={classes.formSubgroup}>
					<TextField
						id='amount_ref'
						label='Monto de referencia'
						name='amount_ref'
						defaultValue='0'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<TextField
						id='min_amount'
						label='Monto minimo'
						name='min_amount'
						defaultValue='0'
						variant='outlined'
						margin='dense'
						type='number'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<TextField
						id='activated_at'
						label='Activo desde'
						name='activated_at'
						defaultValue={getFormattedToday()}
						variant='outlined'
						margin='dense'
					/>
					<TextField
						id='finished_at'
						label='Finaliza en'
						name='finished_at'
						defaultValue={getFormattedTomorrow()}
						variant='outlined'
						margin='dense'
					/>
				</div>
				<div className={classes.formSubgroup}>
					<TextField
						id='account_type'
						label='Tipo de cuenta'
						name='account_type'
						defaultValue='all'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<TextField
						id='business_type'
						label='Tipo de negocio'
						name='business_type'
						defaultValue='1'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					<TextField
						id='active'
						label='Activo'
						name='active'
						defaultValue='true'
						variant='outlined'
						margin='dense'
						inputProps={{
							readOnly: true,
						}}
						className={classes.readonlyInput}
					/>
					{/*
					<FormControlLabel
						control={<Checkbox defaultChecked variant='outlined' />}
						id='active'
						name='active'
						label='active'
					/>
					*/}
					<TextField
						id='max_usage'
						label='Uso maximo'
						name='max_usage'
						defaultValue='1'
						variant='outlined'
						margin='dense'
					/>
				</div>
				<Button type='submit'>Enviar datos</Button>
			</form>
		</div>
	)
}

export default Cupones
