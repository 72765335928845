import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
import { admin } from './admin.reducer'
import { alert } from './alert.reducer'

const rootReducer = combineReducers({
	authentication,
	admin,
	alert,
})

export default rootReducer
