import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { triggerBase64Download } from 'react-base64-downloader'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { green, red } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminActions } from '../_actions'
import { useSuccess } from '../_hooks'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	paper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 16px',
		flexDirection: 'column',
		margin: theme.spacing(2),
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	loading: {
		margin: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	success: {
		backgroundColor: green[300],
	},
	failure: {
		backgroundColor: red[300],
	},
	cvu_success: {
		backgroundColor: green[300],
	},
	cvu_failure: {
		backgroundColor: red[300],
	},
	clear: {
		backgroundColor: '#ffffff',
	},
	title: {
		fontWeight: 'bold',
	},
	response: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		alignItems: 'center',
	},
}))

function GenerateCvu() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [error, setError] = useState({
		cuit: false,
		cvu: false,
	})
	const {
		companyValidated,
		cvuGenerated,
		generatingCvu,
		companyData,
		qrImage,
	} = useSelector((state) => state.admin)
	const cvu_success = useSuccess(cvuGenerated)
	const cuit = companyData?.document

	const generateCVU = async () => {
		if (cuit) {
			const data = {
				cuit: cuit,
				externalRef: companyData.external_ref,
				fantasyName: companyData.company_name,
				businessName: companyData.business_name,
				hasQr: companyData.has_qr,
			}
			dispatch(adminActions.generateCvu(data))
			setError({
				cvu: false,
			})
		} else {
			setError({
				...error,
				cvu: true,
			})
		}
	}

	const downloadQR = async () => {
		if (cuit) {
			dispatch(adminActions.downloadQR(cuit, companyData.city))
			setError({
				qr: false,
			})
		} else {
			setError({
				...error,
				qr: true,
			})
		}
	}

	useEffect(() => {
		qrImage && triggerBase64Download(qrImage, 'qri')
	}, [qrImage])

	return (
		<Paper
			className={
				companyValidated
					? clsx([classes.paper, classes.response, classes[cvu_success]])
					: null
			}
		>
			{generatingCvu ? (
				<CircularProgress size={24} className={classes.loading} />
			) : companyValidated ? (
				<>
					<Typography variant='caption'>
						{`Comercio: ${companyData.company_name}`}
					</Typography>
					<Typography variant='caption'>
						{`Nombre: ${companyData.business_name}`}
					</Typography>
					<Typography variant='caption'>{`Email: ${companyData.email}`}</Typography>
					{companyData?.has_qr ? (
						<>
							<Typography variant='caption'>Tiene QR: Sí</Typography>
							<Button
								variant='contained'
								color='primary'
								className={classes.button}
								onClick={downloadQR}
							>
								DESCARGAR QR
							</Button>
						</>
					) : (
						<>
							<Typography variant='caption'>Tiene QR: No</Typography>
							<Button
								variant='contained'
								color='primary'
								className={classes.button}
								onClick={generateCVU}
							>
								GENERAR CVU
							</Button>
						</>
					)}
				</>
			) : null}
		</Paper>
	)
}

export { GenerateCvu }
