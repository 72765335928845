import { baseUrl } from '../_constants/baseTapUrl'
import { apiGatewayUrl } from '../_constants/apiGatewayUrl'
import { apiQrUrl } from '../_constants/apiQrUrl'
import { authHeader, parseCouponForm } from '../_helpers'

export const adminService = {
	addWhitelist,
	deleteFromBlacklist,
	checkBlacklist,
	addBlacklist,
	updateBlacklistUser,
	addCoupon,
	validateCompany,
	generateCvu,
	downloadQR,
}

async function addWhitelist(external_ref) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({
			external_ref: external_ref ? external_ref : undefined,
		}),
	}
	const fetchUrl = `${baseUrl}/private/whitelist_card`
	return handleFetch(fetchUrl, requestOptions)
}

async function deleteFromBlacklist(
	external_ref,
	device_id,
	advertising_id,
	description,
	author,
	source
) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({
			external_ref: external_ref ? external_ref : undefined,
			device_id: device_id ? device_id : undefined,
			advertising_id: advertising_id ? advertising_id : undefined,
			description,
			author,
			source,
		}),
	}
	const fetchUrl = `${baseUrl}/private/blacklist_delete`
	return handleFetch(fetchUrl, requestOptions)
}

async function checkBlacklist(external_ref, device_id, advertising_id) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() },
	}
	const fetchUrl = `${baseUrl}/private/blacklist_auth?external_ref[equal]=${
		external_ref || undefined
	}&device_id[equal]=${device_id || undefined}&advertising_id[equal]=${
		advertising_id || undefined
	}`
	return handleFetch(fetchUrl, requestOptions)
}

async function addBlacklist(
	external_ref,
	device_id,
	advertising_id,
	description,
	author,
	source
) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({
			external_ref: external_ref ? external_ref : undefined,
			device_id: device_id ? device_id : undefined,
			advertising_id: advertising_id ? advertising_id : undefined,
			description,
			author,
			source,
		}),
	}
	const fetchUrl = `${baseUrl}/private/blacklist_auth`
	return handleFetch(fetchUrl, requestOptions)
}

function updateBlacklistUser(external_ref, device_id, active) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ external_ref, device_id, active }),
	}
	const fetchUrl = `${baseUrl}/private/blacklist_auth`
	return handleFetch(fetchUrl, requestOptions)
}

async function addCoupon(form) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'x-libby-app': 'WalletApp',
		},
		body: JSON.stringify(parseCouponForm(form)),
	}
	const fetchUrl = `${baseUrl}/private/promotion_create`
	return handleFetch(fetchUrl, requestOptions)
}

async function validateCompany(cuit) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() },
	}
	const fetchUrl = `${baseUrl}/private/company_qr?document[equal]=${cuit}`
	return handleFetch(fetchUrl, requestOptions)
}

async function generateCvu(
	cuit,
	externalRef,
	fantasyName,
	businessName,
	hasQr
) {
	const data = {
		document: cuit,
		external_ref: externalRef,
		fantasy_name: fantasyName,
		business_name: businessName,
		has_qr: hasQr,
	}

	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	}
	const fetchUrl = `${apiGatewayUrl}/cvu`
	return handleFetch(fetchUrl, requestOptions)
}

async function downloadQR(cuit, city) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({
			cuit: cuit || undefined,
			city: city || undefined,
		}),
	}
	const fetchUrl = `${apiQrUrl}/encode-qr-image`
	return handleFetch(fetchUrl, requestOptions)
}

async function handleFetch(fetchUrl, options) {
	try {
		const res = await fetch(fetchUrl, options)
		if (!res.ok) {
			const text = await res.json()

			throw text
		}
		const bodyRes = await res.json()
		return bodyRes
	} catch (err) {
		console.warn(err)
		return Promise.reject(err)
	}
}
