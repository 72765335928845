import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminActions } from '../_actions'
import { useSuccess } from '../_hooks'
import { BLACKLIST_REMOVE_CATEGORIES } from '../_constants'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	paper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2),
		flexDirection: 'column',
		transition: theme.transitions.create(['background-color'], {
			duration: theme.transitions.duration.standard,
		}),
	},
	success: {
		backgroundColor: green[300],
	},
	failure: {
		backgroundColor: red[300],
	},
	clear: {
		backgroundColor: '#ffffff',
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	loading: {
		margin: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	title: {
		fontWeight: 'bold',
	},
	error: {
		color: red[300],
		fontWeight: 'bold',
		'&:first-of-type': {
			marginTop: '20px',
		},
	},
}))

function DeleteBlacklist(props) {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [error, setError] = useState({
		fields: false,
		description_type: false,
	})
	const [deleteBlacklistInputs, setDeleteBlacklistInputs] = useState({
		external_ref: '',
		device_id: '',
		advertising_id: '',
		description_type: '',
		additional_info: '',
	})
	const {
		external_ref,
		device_id,
		advertising_id,
		description_type,
		additional_info,
	} = deleteBlacklistInputs

	const success = useSelector((state) =>
		useSuccess(state.admin.deleteFromBlacklist)
	)
	const deletingFromBlacklist = useSelector(
		(state) => state.admin.deletingFromBlacklist
	)
	const author = useSelector((state) => {
		const {
			authentication: {
				user: { name, last_name, account_hash },
			},
		} = state
		return `${name} ${last_name} | ${account_hash}`
	})
	const options = BLACKLIST_REMOVE_CATEGORIES

	const handleDeleteBlacklistInputsChange = (e) => {
		const { name, value } = e.target
		setDeleteBlacklistInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	const handleDescriptionTypeChange = (e) => {
		const { value } = e.target
		setDeleteBlacklistInputs((inputs) => ({
			...inputs,
			description_type: value,
		}))
	}

	const checkIfErrors = () => {
		setError({
			fields: !external_ref && !device_id && !advertising_id,
			description_type: !description_type,
		})

		return (!external_ref && !device_id && !advertising_id) || !description_type
	}

	const deleteBlacklist = () => {
		if (checkIfErrors()) return
		dispatch(
			adminActions.deleteFromBlacklist(
				external_ref,
				device_id,
				advertising_id,
				`${description_type}${additional_info ? ' | ' + additional_info : ''}`,
				author
			)
		)
		setError({
			fields: false,
			description: false,
		})
	}

	return (
		<>
			<Typography variant='subtitle1' gutterBottom className={classes.title}>
				Eliminar de Blacklist
			</Typography>
			<Paper className={clsx(classes.paper, classes[success])}>
				{deletingFromBlacklist ? (
					<CircularProgress size={24} className={classes.loading} />
				) : (
					<>
						<TextField
							variant='outlined'
							margin='normal'
							value={external_ref}
							onChange={handleDeleteBlacklistInputsChange}
							label='external_ref'
							name='external_ref'
							className={classes.input}
							error={error.external_ref}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							value={device_id}
							onChange={handleDeleteBlacklistInputsChange}
							label='device_id'
							name='device_id'
							className={classes.input}
							error={error.device_id}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							value={advertising_id}
							onChange={handleDeleteBlacklistInputsChange}
							label='advertising_id'
							name='advertising_id'
							className={classes.input}
							error={error.advertising_id}
						/>
						<FormControl variant='outlined' error={error.description_type}>
							<InputLabel id='description_type'>Descripción *</InputLabel>
							<Select
								id='description_type'
								labelId='description_type'
								required
								value={description_type}
								onChange={handleDescriptionTypeChange}
								label='Descripción *'
							>
								{options.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							variant='outlined'
							margin='normal'
							value={additional_info}
							onChange={handleDeleteBlacklistInputsChange}
							className={classes.input}
							label='Información adicional'
							name='additional_info'
						/>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={deleteBlacklist}
						>
							delete from blacklist
						</Button>
						{error.fields && (
							<Typography className={classes.error} variant='subtitle2' align='left'>
								Debe enviar por lo menos un campo para añadir
							</Typography>
						)}
						{error.description_type && (
							<Typography className={classes.error} variant='subtitle2' align='left'>
								Es necesaria una descripción
							</Typography>
						)}
					</>
				)}
			</Paper>
		</>
	)
}

export { DeleteBlacklist }
