import React, { useState } from 'react'
import CouponInput from './CouponInput'
import ConfirmPage from './ConfirmPage'

function CouponsPage() {
	const [formDataInput, setFormDataInput] = useState()
	const [isShowingInputs, setIsShowingInputs] = useState(true)
	const [storageFormData, setStorageFormData] = useState()

	return (
		<>
			{isShowingInputs ? (
				<CouponInput
					setFormDataInput={(e) => setFormDataInput(e)}
					setIsShowingInputs={() => setIsShowingInputs(false)}
					setStorageFormData={(e) => setStorageFormData(e)}
				/>
			) : (
				<ConfirmPage
					{...formDataInput}
					storageFormData={storageFormData}
					goBack={() => setIsShowingInputs(true)}
				/>
			)}
		</>
	)
}

export default CouponsPage
