import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
	Divider,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Drawer,
	List,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListAltIcon from '@material-ui/icons/ListAlt'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import StoreOutlined from '@material-ui/icons/StoreOutlined'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import { UserControl } from '../UserControl'
import { Cupones } from '../Cupones'
import { BatchUpload } from '../BatchUpload'
import { HOMEVIEWS } from '../_constants'
import { TapLogo } from './TapLogo'
import CouponsPage from '../Cupones/CouponsPage'
import { CompanyValidation } from '../CompanyValidation'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100vh',
	},
	paper: {
		marginTop: theme.spacing(4),
		marginLeft: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	category: {
		display: 'flex',
		flexDirection: 'column',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		color: '#002350',
		textShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
	},
	name: {
		marginRight: theme.spacing(2),
		textShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		background: 'linear-gradient(253.8deg, #8C91FF -39.26%, #14D2B9 44.36%)',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButtonHidden: {
		display: 'none',
	},
	drawerPaper: {
		position: 'relative',
		backgroundColor: theme.palette.background.default,
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	listWrapper: {
		padding: 0,
	},
}))

function ViewSwitch({ view }) {
	switch (view) {
		case HOMEVIEWS.USERCONTROL:
			return <UserControl />
		case HOMEVIEWS.BATCHUPLOAD:
			return <BatchUpload />
		case HOMEVIEWS.CUPON:
			return <CouponsPage />
		case HOMEVIEWS.COMPANYVALIDATION:
			return <CompanyValidation />
		default:
			return <UserControl />
	}
}

function HomePage() {
	const classes = useStyles()
	const user = useSelector((state) => state.authentication.user)
	const { name, last_name } = user
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [currentView, setCurrentView] = useState(HOMEVIEWS.USERCONTROL)
	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen)
	}
	const handleOnNavigationClick = (loc) => {
		setCurrentView(loc)
	}

	return (
		<div className={classes.root}>
			<AppBar
				position='fixed'
				className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerToggle}
						className={clsx(
							classes.menuButton,
							drawerOpen && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<span
						style={{ margin: '16px 12px 16px 8px', height: '24px', width: '24px' }}
					>
						<TapLogo />
					</span>
					<Typography variant='h6' className={classes.title}>
						{currentView}
					</Typography>
					<Typography variant='body1' className={classes.name}>
						{name} {last_name}
					</Typography>
					<Link to='/login'>
						<IconButton>
							<ExitToAppRoundedIcon />
						</IconButton>
					</Link>
				</Toolbar>
			</AppBar>
			<Drawer
				variant='permanent'
				classes={{
					paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
				}}
				open={drawerOpen}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerToggle}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List className={classes.listWrapper}>
					<ListItem
						button
						onClick={() => handleOnNavigationClick(HOMEVIEWS.USERCONTROL)}
					>
						<ListItemIcon>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary={HOMEVIEWS.USERCONTROL} />
					</ListItem>
					<Divider />
					<ListItem button onClick={() => handleOnNavigationClick(HOMEVIEWS.CUPON)}>
						<ListItemIcon>
							<CardGiftcardIcon />
						</ListItemIcon>
						<ListItemText primary={HOMEVIEWS.CUPON} />
					</ListItem>
					<Divider />
					<ListItem
						button
						onClick={() => handleOnNavigationClick(HOMEVIEWS.BATCHUPLOAD)}
					>
						<ListItemIcon>
							<ListAltIcon />
						</ListItemIcon>
						<ListItemText primary={HOMEVIEWS.BATCHUPLOAD} />
					</ListItem>
					<Divider />
					<ListItem
						button
						onClick={() => handleOnNavigationClick(HOMEVIEWS.COMPANYVALIDATION)}
					>
						<ListItemIcon>
							<StoreOutlined />
						</ListItemIcon>
						<ListItemText primary={HOMEVIEWS.COMPANYVALIDATION} />
					</ListItem>
				</List>
				<Divider />
			</Drawer>
			<ViewSwitch view={currentView} />
		</div>
	)
}

export { HomePage }
