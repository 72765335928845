export const STATUS_FLAGS = {
	SUCCESS: 'success',
	FAILURE: 'failure',
	CLEAR: 'clear',
}

export const useSuccess = (status) => {
	switch (status) {
		case undefined:
			return STATUS_FLAGS.CLEAR
		case true:
			return STATUS_FLAGS.SUCCESS
		case false:
			return STATUS_FLAGS.FAILURE
		default:
			return STATUS_FLAGS.CLEAR
	}
}
