import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { green, red } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminActions } from '../_actions'
import { useSuccess } from '../_hooks'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	paper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 16px',
		flexDirection: 'column',
		margin: theme.spacing(2),
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	loading: {
		margin: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	success: {
		backgroundColor: green[300],
	},
	failure: {
		backgroundColor: red[300],
	},
	cvu_success: {
		backgroundColor: green[300],
	},
	cvu_failure: {
		backgroundColor: red[300],
	},
	clear: {
		backgroundColor: '#ffffff',
	},
	title: {
		fontWeight: 'bold',
	},
	response: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		alignItems: 'center',
	},
}))

function ValidateCompany() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [error, setError] = useState({
		cuit: false,
		cvu: false,
	})
	const [companyInput, setCompanyInput] = useState({
		cuit: '',
	})
	const companyValidated = useSelector((state) => state.admin.companyValidated)
	const success = useSuccess(companyValidated)
	const { cuit } = companyInput
	const validatingCompany = useSelector((state) => state.admin.validatingCompany)

	const handleCompanyInputChange = (e) => {
		const { name, value } = e.target
		setCompanyInput((input) => ({ ...input, [name]: value }))
	}

	const checkCompanyByCUIT = async () => {
		if (cuit) {
			dispatch(adminActions.validateCompany(cuit))
			setError({
				cuit: false,
			})
		} else {
			setError({
				...error,
				cuit: true,
			})
		}
	}

	return (
		<>
			<Typography variant='subtitle1' className={classes.title}>
				Validar cuenta comercio
			</Typography>
			<Typography variant='caption' gutterBottom>
				Completa el formulario con el CUIT del comercio para corroborar sus datos
			</Typography>
			<Paper className={clsx(classes.paper, classes[success])}>
				{validatingCompany ? (
					<CircularProgress size={24} className={classes.loading} />
				) : (
					<>
						<TextField
							variant='outlined'
							margin='normal'
							value={cuit}
							required
							onChange={handleCompanyInputChange}
							className={classes.input}
							label='CUIT'
							name='cuit'
							error={error.cuit}
						/>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={checkCompanyByCUIT}
						>
							VALIDAR COMERCIO
						</Button>
					</>
				)}
			</Paper>
		</>
	)
}

export { ValidateCompany }
