import { alertConstants } from '../_constants'

export const alertActions = {
	success,
	loading,
	error,
	clear,
	info,
}

function success(message) {
	return { type: alertConstants.SUCCESS, message }
}

function loading(message) {
	return { type: alertConstants.LOADING, message }
}

function error(message, fields) {
	return { type: alertConstants.ERROR, message, ...(fields ? { fields } : {}) }
}

function clear() {
	return { type: alertConstants.CLEAR }
}

function info(message) {
	return { type: alertConstants.INFO, message }
}
