import { alertConstants } from '../_constants'

/*
 * Alert types from MaterialUI:
 *  error
 *  warning
 *  info
 *  success
 */

export function alert(state = {}, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				type: 'success',
				message: action.message,
			}
		case alertConstants.ERROR:
			return {
				type: 'error',
				message: action.message,
				fields: action.fields,
			}
		case alertConstants.LOADING:
			return {
				type: 'info',
				message: action.message,
			}
		case alertConstants.INFO:
			return {
				type: 'info',
				message: action.message,
			}
		case alertConstants.CLEAR:
			return {}
		default:
			return state
	}
}
