export const BACKOFFICE_SOURCE = 'backoffice'

export const BLACKLIST_ADD_CATEGORIES = [
	'Contracargos',
	'Red de Fraude',
	'Cuenta denunciada victima de fraude',
	'Cuenta denunciada entidad bancaria',
	'Caso AML',
	'Comparte device con usuario en BL',
	'Comparte advertising_id con usuario en BL',
	'Asocia TJ de persona en BL',
	'Conexión con CBU en BL',
	'Opera intensamente en servicios/recargas',
	'Múltiples TX entre cuentas',
	'Relacionado a cash-in',
	'Múltiples intentos por asociar TJ',
	'Desconoce movimientos',
	'Cuentas con mismo nombre creadas el mismo día',
	'Falsifica PDV',
	'Abuso promocodes',
	'Asocia TJ que estaba en otra cuenta',
	'Prepaid pierde TJ',
	'Solicita eliminar cuenta',
	'Robo de celular',
	'Vinculado con usuario con CBKs',
	'Emails Temporales',
	'Compliance',
	'Abuso Cashback',
	'Opera intensamente con LDP',
	'Comparte advertising_id con persona con CBKs',
	'Otro',
]

export const BLACKLIST_REMOVE_CATEGORIES = [
	'Presenta PDV',
	'Presenta extracto de tarjeta',
	'Presenta PDV y extracto de tarjeta',
	'Presenta documentación Compliance',
	'Otro (aclarar)',
]
