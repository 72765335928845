export const adminConstants = {
	ADDWHITELIST_REQUEST: 'ADMIN_ADDWHITELIST_REQUEST',
	ADDWHITELIST_SUCCESS: 'ADMIN_ADDWHITELIST_SUCCESS',
	ADDWHITELIST_FAILURE: 'ADMIN_ADDWHITELIST_FAILURE',

	DELETEFROMBLACKLIST_REQUEST: 'ADMIN_DELETEFROMBLACKLIST_REQUEST',
	DELETEFROMBLACKLIST_SUCCESS: 'ADMIN_DELETEFROMBLACKLIST_SUCCESS',
	DELETEFROMBLACKLIST_FAILURE: 'ADMIN_DELETEFROMBLACKLIST_FAILURE',

	CHECKBLACKLIST_REQUEST: 'ADMIN_CHECKBLACKLIST_REQUEST',
	CHECKBLACKLIST_SUCCESS: 'ADMIN_CHECKBLACKLIST_SUCCESS',
	CHECKBLACKLIST_FAILURE: 'ADMIN_CHECKBLACKLIST_FAILURE',

	ADDBLACKLIST_REQUEST: 'ADMIN_ADDBLACKLIST_REQUEST',
	ADDBLACKLIST_SUCCESS: 'ADMIN_ADDBLACKLIST_SUCCESS',
	ADDBLACKLIST_FAILURE: 'ADMIN_ADDBLACKLIST_FAILURE',

	UPDATEBLACKLISTUSER_REQUEST: 'ADMIN_UPDATEBLACKLISTUSER_REQUEST',
	UPDATEBLACKLISTUSER_SUCCESS: 'ADMIN_UPDATEBLACKLISTUSER_SUCCESS',
	UPDATEBLACKLISTUSER_FAILURE: 'ADMIN_UPDATEBLACKLISTUSER_FAILURE',

	ADDCOUPON_REQUEST: 'ADMIN_ADDCOUPON_REQUEST',
	ADDCOUPON_SUCCESS: 'ADMIN_ADDCOUPON_SUCCESS',
	ADDCOUPON_FAILURE: 'ADMIN_ADDCOUPON_FAILURE',

	CLEAR: 'CLEAR',

	VALIDATECUIT_REQUEST: 'ADMIN_VALIDATECUIT_REQUEST',
	VALIDATECUIT_SUCCESS: 'ADMIN_VALIDATECUIT_SUCCESS',
	VALIDATECUIT_FAILURE: 'ADMIN_VALIDATECUIT_FAILURE',

	GENERATECVU_REQUEST: 'ADMIN_GENERATECVU_REQUEST',
	GENERATECVU_SUCCESS: 'ADMIN_GENERATECVU_SUCCESS',
	GENERATECVU_FAILURE: 'ADMIN_GENERATECVU_FAILURE',

	DOWNLOADQR_REQUEST: 'ADMIN_DOWNLOADQR_REQUEST',
	DOWNLOADQR_SUCCESS: 'ADMIN_DOWNLOADQR_SUCCESS',
	DOWNLOADQR_FAILURE: 'ADMIN_DOWNLOADQR_FAILURE',
}
