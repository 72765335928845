import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { green, red } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminActions } from '../_actions'
import { useSuccess } from '../_hooks'

const useStyles = makeStyles((theme) => ({
	button: {
		margin: 'auto 0',
		color: 'white',
		backgroundColor: theme.palette.tertiary.main,
	},
	paper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2),
		flexDirection: 'column',
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	loading: {
		margin: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	success: {
		backgroundColor: green[300],
	},
	failure: {
		backgroundColor: red[300],
	},
	clear: {
		backgroundColor: '#ffffff',
	},
	title: {
		fontWeight: 'bold',
	},
}))

function AddWhitelistCard() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [error, setError] = useState({
		external_ref: false,
	})
	const [external_ref, setExternal_ref] = useState('')
	const addingToWhitelistCard = useSelector(
		(state) => state.admin.addingToWhitelistCard
	)
	const success = useSuccess(addingToWhitelistCard)
	const InWhitelistCard = useSelector((state) => state.admin.InWhitelistCard)

	const handleWhitelistInputChange = (e) => {
		const { name, value } = e.target
		setExternal_ref(value)
	}

	const addWhitelistCard = () => {
		if (external_ref) {
			dispatch(adminActions.addWhitelist(external_ref))
			setError({
				external_ref: false,
			})
		} else {
			setError({
				...error,
				external_ref: true,
			})
		}
	}

	return (
		<section>
			<Typography variant='subtitle1' className={classes.title}>
				Agregar a Whitelist de Card
			</Typography>
			<Paper className={clsx(classes.paper, classes[success])}>
				{InWhitelistCard ? (
					<CircularProgress size={24} className={classes.loading} />
				) : (
					<>
						<TextField
							variant='outlined'
							margin='normal'
							value={external_ref}
							onChange={handleWhitelistInputChange}
							className={classes.input}
							label='external_ref'
							name='external_ref'
							error={error.external_ref}
						/>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={addWhitelistCard}
						>
							Agregar Whitelist Card
						</Button>
					</>
				)}
			</Paper>
		</section>
	)
}

export { AddWhitelistCard }
