import { adminConstants } from '../_constants'

export function admin(state = {}, action) {
	switch (action.type) {
		case adminConstants.ADDWHITELIST_REQUEST:
			return {
				addingToWhitelistCard: true,
				external_ref: action.external_ref,
			}
		case adminConstants.ADDWHITELIST_SUCCESS:
			return {
				inWhitelistCard: true,
				external_ref: action.external_ref,
			}
		case adminConstants.ADDWHITELIST_FAILURE:
			return {
				inWhitelistCard: false,
				external_ref: action.external_ref,
			}
		case adminConstants.DELETEFROMBLACKLIST_REQUEST:
			return {
				deletingFromBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}
		case adminConstants.DELETEFROMBLACKLIST_SUCCESS:
			return {
				deleteFromBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}
		case adminConstants.DELETEFROMBLACKLIST_FAILURE:
			return {
				deleteFromBlacklist: false,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}

		case adminConstants.CHECKBLACKLIST_REQUEST:
			return {
				checkingBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}
		case adminConstants.CHECKBLACKLIST_SUCCESS: // success => no esta en blacklist
			return {
				inBlacklist: true,
				valid: action.valid,
				tx: action.tx,
			}
		case adminConstants.CHECKBLACKLIST_FAILURE:
			return {
				inBlacklist: false,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}

		case adminConstants.ADDBLACKLIST_REQUEST:
			return {
				addingToBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}
		case adminConstants.ADDBLACKLIST_SUCCESS:
			return {
				addedToBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}
		case adminConstants.ADDBLACKLIST_FAILURE:
			return {
				addedToBlacklist: false,
				external_ref: action.external_ref,
				device_id: action.device_id,
				advertising_id: action.advertising_id,
			}

		case adminConstants.UPDATEBLACKLISTUSER_REQUEST:
			return {
				updatingBlacklist: true,
				external_ref: action.external_ref,
				device_id: action.device_id,
				active: action.active,
			}
		case adminConstants.UPDATEBLACKLISTUSER_SUCCESS:
			return {
				updateBlacklistSuccess: true,
				updatedBlacklistStatus: action.active,
				external_ref: action.external_ref,
				device_id: action.device_id,
			}
		case adminConstants.UPDATEBLACKLISTUSER_FAILURE:
			return {
				updateBlacklistSuccess: false,
				external_ref: action.external_ref,
				device_id: action.device_id,
			}

		case adminConstants.ADDCOUPON_REQUEST:
			return {
				addingCoupon: true,
				name: action.couponName,
			}
		case adminConstants.ADDCOUPON_SUCCESS:
			return {
				addCouponSuccess: true,
				name: action.couponName,
			}
		case adminConstants.ADDCOUPON_FAILURE:
			return {
				addCouponSuccess: false,
				name: action.couponName,
			}
		case adminConstants.VALIDATECUIT_REQUEST:
			return {
				validatingCompany: true,
				cuit: action.cuit,
			}
		case adminConstants.VALIDATECUIT_SUCCESS:
			return {
				companyValidated: true,
				companyData: action.data,
			}
		case adminConstants.VALIDATECUIT_FAILURE:
			return {
				companyValidated: false,
				cuit: action.cuit,
			}
		case adminConstants.GENERATECVU_REQUEST:
			return {
				generatingCvu: true,
				cuit: action.cuit,
			}
		case adminConstants.GENERATECVU_SUCCESS:
			return {
				cvuGenerated: true,
			}
		case adminConstants.GENERATECVU_FAILURE:
			return {
				cvuGenerated: false,
			}
		case adminConstants.DOWNLOADQR_REQUEST:
			return {
				downloadingQr: true,
				cuit: action.cuit,
			}
		case adminConstants.DOWNLOADQR_SUCCESS:
			return {
				qrDownloaded: true,
				qrImage: action.data,
			}
		case adminConstants.DOWNLOADQR_FAILURE:
			return {
				qrDownloaded: false,
			}
		case adminConstants.CLEAR:
			return {}
		default:
			return state
	}
}
