import React from 'react'

export function TapLogo() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 103 103'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M69.2933 69.4296C59.3942 69.4296 51.3988 61.4185 51.3988 51.5C51.3988 41.5815 59.3942 33.5703 69.2933 33.5703H94.8023C89.0913 25.5592 79.573 20.2185 68.9125 20.2185C51.7796 20.2185 37.6925 34.3333 37.6925 51.5C37.6925 68.6666 51.7796 82.7815 68.9125 82.7815C79.573 82.7815 89.0913 77.4407 94.8023 69.4296H69.2933Z'
				fill='#FBFBFB'
			/>
			<path
				d='M94.4216 87.7407C94.4216 91.5556 91.7564 94.6074 88.7106 94.6074H8.37611V8.39259H88.7106C91.7564 8.39259 94.4216 11.4444 94.4216 15.2593V33.9519H102.798V15.2593C102.798 6.86667 96.3252 0 88.7106 0H0V103H88.7106C96.3252 103 102.798 96.1333 102.798 87.7407V69.0481H94.4216V87.7407Z'
				fill='#002350'
			/>
			<g opacity='0.5'>
				<g opacity='0.5'>
					<g opacity='0.5'>
						<path
							opacity='0.5'
							d='M62.8208 68.2852L84.9033 78.5852C88.7106 76.2963 92.1372 73.2444 94.8023 69.4296H69.2933C67.0089 69.4296 64.7245 69.0481 62.8208 68.2852Z'
							fill='#002350'
						/>
					</g>
				</g>
			</g>
			<path
				d='M68.9125 40.8185H73.1006C78.8116 40.8185 83.7611 45.3963 83.7611 51.5C83.7611 57.6037 79.1923 62.1815 73.1006 62.1815H68.9125C63.2015 62.1815 58.252 57.6037 58.252 51.5C58.252 45.3963 62.8208 40.8185 68.9125 40.8185Z'
				fill='#002350'
			/>
		</svg>
	)
}
