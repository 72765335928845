import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#14D2B9',
		},
		secondary: {
			main: '#002350',
		},
		tertiary: {
			main: '#8C91FF',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#E5E5E5',
		},
	},
})

export default theme
